import { MenusModule } from '@progress/kendo-angular-menu';
import { WidthLengthPipe } from './pipes/width-length.pipe';
import { SerialPipe } from './pipes/serial.pipe';
import { SizePipe } from './pipes/size.pipe';
import { LengthPipe } from './pipes/length.pipe';
import { WidthPipe } from './pipes/width.pipe';

import { CustomerAddressPipe } from './pipes/customer-address.pipe';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { NotificationModule } from '@progress/kendo-angular-notification';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';

import { NgxSpinnerModule } from 'ngx-spinner';

import { HideIfUnauthorizedDirective } from './directives/hide-if-unauthorized.directive';
import { PopupAnchorDirective } from './directives/app-popup-anchor.directive';

import { UserOperationsResolver } from './resolvers/user-operations.resolver';

import { NavService } from './layout/navbar/nav.service';

import { NavbarComponent } from './layout/navbar/navbar.component';
import { HeaderComponent } from './layout/header/header.component';
import { MenuListItemComponent } from './layout/menu-list-item/menu-list-item.component';
import { GridContextMenuComponent } from './components/grid-context-menu/grid-context-menu.component';
import { BreadcrumbComponent } from './layout/header/breadcrumb/breadcrumb.component';
import { AccountMenuComponent } from './layout/header/account-menu/account-menu.component';
import { FiltersComponent } from './components/filters/filters/filters.component';
import { CommonHeaderControlComponent } from './components/common-header-control/common-header-control.component';
import { StartEndConfirmDialogComponent } from './components/start-end-confirm-dialog/start-end-confirm-dialog.component';

import { ShipToCustomerPipe } from './pipes/ship-to-customer.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { ShipAddressPipe } from './pipes/ship-address.pipe';
import { DeliveryWarehouseNamePipe } from './pipes/delivery-warehouse-name.pipe';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ProjectSizePipe } from './pipes/project-size.pipe';
import { ProjectSerialPipe } from './pipes/project-serial.pipe';
import { LotPipe } from './pipes/lot.pipe';
import { FilterDialogComponent } from './components/filters/filter-dialog/filter-dialog.component';
import { FilterStringComponent } from './components/filters/filter-string/filter-string.component';
import { FilterNumericComponent } from './components/filters/filter-numeric/filter-numeric.component';
import { FilterDateComponent } from './components/filters/filter-date/filter-date.component';
import { FilterTemplateDirective } from './components/filters/filter-dialog/filter-template.directive';
import { FilterListComponent } from './components/filters/filter-list/filter-list.component';
import { OoserviceConfirmDialogComponent } from './components/ooservice-confirm-dialog/ooservice-confirm-dialog.component';
import { FilterBooleanComponent } from './components/filters/filter-boolean/filter-boolean.component';
import { CastPipe } from './pipes/cast.pipe';
import { QuoteLeadNamePipe } from './pipes/quote-lead-name.pipe';
import { IntlModule } from '@progress/kendo-angular-intl';
import { IframeWrapperComponent } from './components/iframe-wrapper/iframe-wrapper.component';
import { FilterNullValueComponent } from './components/filters/filter-null-value/filter-null-value.component';
import { DocumentStatusPipe } from './pipes/document-status.pipe';
import { LocalDateBySitePipe } from './pipes/local-date-by-site.pipe';
import { IconsModule } from '@progress/kendo-angular-icons';

@NgModule({
  declarations: [
    HideIfUnauthorizedDirective,
    PopupAnchorDirective,
    AutofocusDirective,
    PhoneNumberPipe,
    ShipAddressPipe,
    DeliveryWarehouseNamePipe,
    ShipToCustomerPipe,
    CustomerAddressPipe,
    WidthPipe,
    LengthPipe,
    SizePipe,
    ProjectSizePipe,
    ProjectSerialPipe,
    SerialPipe,
    LotPipe,
    WidthLengthPipe,
    CastPipe,
    QuoteLeadNamePipe,
    DocumentStatusPipe,
    LocalDateBySitePipe,
    FiltersComponent,
    BreadcrumbComponent,
    NavbarComponent,
    HeaderComponent,
    MenuListItemComponent,
    CommonHeaderControlComponent,
    GridContextMenuComponent,
    StartEndConfirmDialogComponent,
    FilterDialogComponent,
    FilterStringComponent,
    FilterNumericComponent,
    FilterDateComponent,
    FilterBooleanComponent,
    FilterTemplateDirective,
    FilterListComponent,
    OoserviceConfirmDialogComponent,
    AccountMenuComponent,
    IframeWrapperComponent,
    FilterNullValueComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  //   NgxSpinnerModule,
    NotificationModule,
    DialogsModule,
    ButtonsModule,
    DropDownsModule,
    InputsModule,
    DatePickerModule,
  //   PopupModule,
    MenusModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    IconsModule,
  //   MatSidenavModule, // TODO remove this
  //   ApiAuthorizationModule,
    GridModule
  ],
  providers: [
    WidthLengthPipe, DatePipe, UserOperationsResolver, NavService
  ],
    exports: [
        HideIfUnauthorizedDirective,
        PopupAnchorDirective,
        AutofocusDirective,

        PhoneNumberPipe,
        ShipAddressPipe,
        DeliveryWarehouseNamePipe,
        ShipToCustomerPipe,
        CustomerAddressPipe,
        WidthPipe,
        LengthPipe,
        SizePipe,
        ProjectSizePipe,
        ProjectSerialPipe,
        SerialPipe,
        LotPipe,
        WidthLengthPipe,
        CastPipe,
        QuoteLeadNamePipe,
        DocumentStatusPipe,
        LocalDateBySitePipe,

        FiltersComponent,
        FilterDialogComponent,
        CommonHeaderControlComponent,
        GridContextMenuComponent,
        NavbarComponent,
        HeaderComponent,

        MatSidenavModule,
        NgxSpinnerModule,
        IntlModule,
        IframeWrapperComponent,
        IconsModule
    ]
})
export class SharedModule {}
