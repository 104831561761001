import { Component, AfterViewInit, ViewChild, ElementRef, ViewEncapsulation, Input, OnInit, OnDestroy } from '@angular/core';
import { NavItem } from './nav-item';
import { NavService } from './nav.service';
import { Operations } from 'src/app/app.constant';
import { OperationService } from 'src/app/core/services/operation.service';
import { Subscription } from 'rxjs';
import { SiteService } from 'src/app/core/services/site.service';
import { first, last } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('closedToggle') closedToggle: ElementRef;
  @Input() appDrawer: MatSidenav;

  navItems: NavItem[] = [];
  operationsSuscription: Subscription;

  constructor(
    private navService: NavService,
    private operationService: OperationService,
    private siteService: SiteService
  ) { }

  ngOnInit() {
    this.operationsSuscription = this.operationService.operations.subscribe(operations => {
      this.siteService.currentSite.pipe(first()).subscribe(site => {
        console.log(
          `${NavbarComponent.name}#${NavbarComponent.prototype.ngOnInit.name}\n` +
          `- Site: ${site}\n` +
          `- Operations: ${JSON.stringify(operations)}`
        );

        var navItems = [];
        if (!site) {
          return;
        }

        navItems.push({
          displayName: 'Home',
          iconName: 'home',
          route: `/${site}`,
          custom: true
        });

        // Check CRUD operations on quotes
        if (operations.find(operation => [
          Operations.QuoteRead.toString(),
          Operations.QuoteCreate.toString(),
          Operations.QuoteUpdate.toString(),
          Operations.QuoteDelete.toString()
        ].indexOf(operation) !== -1)) {
          navItems.push({
            displayName: 'Quotes',
            iconName: 'track-changes-enable',
            route: `${site}/quotes`,
            routeGroup: [
              `${site}/quotes`,
            ],
            kendoIcon: true,
            custom: false
          });
        }

        // Check CRUD operations on orders
        if (operations.find(operation => [
          Operations.OrderRead.toString(),
          Operations.OrderCreate.toString(),
          Operations.OrderUpdate.toString(),
          Operations.OrderDelete.toString()
        ].indexOf(operation) !== -1)) {
          navItems.push({
            displayName: 'Orders',
            iconName: 'order',
            route: `${site}/orders`,
            routeGroup: [
              `${site}/orders`,
              `${site}/orders/return-order/new`,
              `${site}/orders/stock-order/new`,
              `${site}/orders/service-order/new`,
              `${site}/orders/custom-order/new`,
              `${site}/orders/return-order/edit`,
              `${site}/orders/stock-order/edit`,
              `${site}/orders/service-order/edit`,
              `${site}/orders/custom-order/edit`
            ],
            custom: true
          });
        }

        // Check CRUD operations on permit planner
        if (operations.find(operation => [
          Operations.PermitRead.toString(),
          Operations.PermitCreate.toString()
        ].indexOf(operation) > -1)) {
          navItems.push({
            displayName: 'Permit Planner',
            iconName: 'page-header-section',
            kendoIcon: true,
            route: `${site}/permit-planner`,
            custom: false
          });
        }

        // Check CRUD operations on deliveryplanner
        if (operations.find(operation => [
          Operations.DeliveryRead.toString(),
          Operations.DeliveryCreate.toString()
        ].indexOf(operation) > -1)) {
          navItems.push({
            displayName: 'Delivery Planner',
            iconName: 'local_shipping',
            route: `${site}/delivery-planner`,
            custom: false
          });
        }

        // Check CRUD operations on productionplanner
        if (operations.find(operation => [
          Operations.ProductionRead.toString(),
          Operations.ProductionCreate.toString()
        ].indexOf(operation) > -1)) {
          navItems.push({
            displayName: 'Production Planner',
            iconName: 'account_tree',
            route: `${site}/production-planner`,
            custom: false
          });
        }

        // Check CRUD operations on projectplanner
        if (operations.find(operation => [
          Operations.ProjectRead.toString(),
          Operations.ProjectCreate.toString()
        ].indexOf(operation) > -1)) {
          navItems.push({
            displayName: 'Project Planner',
            iconName: 'layout-1-by-4',
            kendoIcon: true,
            route: `${site}/project-planner`,
            custom: false
          });
        }

        // Check CRUD operations on Payment
        if (operations.find(operation => [
          Operations.PaymentRead.toString(),
        ].indexOf(operation) > -1)) {
          navItems.push({
            displayName: 'Payments',
            iconName: 'dollar',
            kendoIcon: true,
            route: `${site}/payments`,
            custom: false
          });
        }

        if (operations.find(operation => [
          Operations.PrebuiltsRead.toString(),
          Operations.PrebuiltsDelete.toString(),
          Operations.PrebuiltsUpdate.toString(),
          Operations.PrebuiltsCreate.toString(),
        ].indexOf(operation) > -1)) {
          navItems.push({
            displayName: 'Prebuilts',
            iconName: 'barcode-outline',
            kendoIcon: true,
            route: `${site}/prebuilts`,
            custom: false
          });
        }

        if (operations.find(operation => [
          Operations.PreownedRead.toString(),
          Operations.PreownedCreate.toString(),
          Operations.PreownedUpdate.toString(),
          Operations.PreownedDelete.toString(),
          Operations.PreownedCreateSerial.toString(),
          Operations.PreownedImport.toString(),
          Operations.PreownedCreateQuote.toString(),
          Operations.PreownedViewQuote.toString(),
          Operations.PreownedViewOrder.toString(),
          Operations.PreownedReturnBuilding.toString()
        ].indexOf(operation) > -1)) {
          navItems.push({
            displayName: 'Preowned',
            iconName: 'paste-plain-text',
            route: `${site}/preowned`,
            kendoIcon: true,
            custom: false
          });
        }

        var pwpMenu: NavItem;
        if (!this.siteService.isHideFncBySite(site)) {
          // Check CRUD and export operations on Workbench
          if (operations.find(operation => [
            Operations.WorkbenchsCreate.toString(),
            Operations.WorkbenchsUpdate.toString(),
            Operations.WorkbenchsRead.toString(),
            Operations.WorkbenchsGenerate.toString(),
            Operations.WorkbenchsItemCreate.toString(),
            Operations.WorkbenchsItemDelete.toString(),
            Operations.WorkbenchsParameter.toString()
          ].indexOf(operation) > -1)) {
            if (!pwpMenu) {
              pwpMenu = this.buildPWPMenu(navItems);
            }
            pwpMenu.children.push({
              displayName: 'PW Workbench',
              iconName: '',
              route: `${site}/piece-work-pay/pw-workbench`,
              custom: false
            });
          }
          // Check CRUD and export operations on Transaction
          if (operations.find(operation => [
            Operations.TransactionsRead.toString(),
            Operations.NppsCreate.toString(),
            Operations.NppsUpdate.toString(),
            Operations.NppsDelete.toString(),
            Operations.TransactionsExport.toString()
          ].indexOf(operation) > -1)) {
            if (!pwpMenu) {
              pwpMenu = this.buildPWPMenu(navItems);
            }
            pwpMenu.children.push({
              displayName: 'PW Transactions',
              iconName: '',
              route: `${site}/piece-work-pay/pw-transactions`,
              custom: false
            });
          }
          
          // Check CRUD and export operations on Payroll
          if (operations.find(operation => [
            Operations.PayrollsRead.toString(),
            Operations.MeetingHoursCreate.toString(),
            Operations.MeetingHoursUpdate.toString(),
            Operations.PayrollsExport.toString()
          ].indexOf(operation) > -1)) {
            if (!pwpMenu) {
              pwpMenu = this.buildPWPMenu(navItems);
            }
            pwpMenu.children.push({
              displayName: 'PW Payroll',
              iconName: '',
              route: `${site}/piece-work-pay/pw-payroll`,
              custom: false
            });
          }

          // Check view and import operations on pw-timecards
          if (operations.find(operation => [
            Operations.EmployeeTimecardsRead.toString(),
            Operations.EmployeeTimecardsImport.toString()
          ].indexOf(operation) > -1)) {
            if (!pwpMenu) {
              pwpMenu = this.buildPWPMenu(navItems);
            }
            pwpMenu.children.push({
              displayName: 'Employee Timecards',
              iconName: '',
              route: `${site}/piece-work-pay/time-cards`,
              custom: false,
            });
        }

        // Check view and export operations on reportearnings
        if (operations.find(operation => [
          Operations.ReportEarningsRead.toString(),
          Operations.ReportEarningsExport.toString(),
        ].indexOf(operation) > -1)) {
          if (!pwpMenu) {
            pwpMenu = this.buildPWPMenu(navItems);
          }
          pwpMenu.children.push({
            displayName: 'PW Earnings Export',
            iconName: '',
            route: `${site}/piece-work-pay/pw-earnings-export`,
            custom: false,
          });
        }

        // Check CRUD and export operations on Employee
        if (operations.find(operation => [
          Operations.EmployeesRead.toString(),
          Operations.EmployeesImport.toString(),
          Operations.EmployeesNPRateUpdate.toString(),
          Operations.EmployeesMinRateUpdate.toString(),
          Operations.EmployeesStatusUpdate.toString(),
          Operations.EmployeesSettingsUpdate.toString(),
          Operations.EmployeesDelete.toString(),
          Operations.EmployeesExport.toString()
        ].indexOf(operation) > -1)) {
          if (!pwpMenu) {
            pwpMenu = this.buildPWPMenu(navItems);
          }
          pwpMenu.children.push({
            displayName: 'Employees',
            iconName: '',
            route: `${site}/piece-work-pay/employees`,
            routeGroup: [`${site}/piece-work-pay/employees`],
            custom: false
          });
        }

        // Check CRUD and export operations on PayPeriods
        if (operations.find(operation => [
          Operations.PayPeriodsCreate.toString(),
          Operations.PayPeriodsLock.toString(),
          Operations.PayPeriodsRead.toString()
        ].indexOf(operation) > -1)) {
          if (!pwpMenu) {
            pwpMenu = this.buildPWPMenu(navItems);
          }
          pwpMenu.children.push({
            displayName: 'Pay Periods',
            iconName: '',
            route: `${site}/piece-work-pay/pay-periods`,
            custom: false
          });
        }

        if (operations.find(operation => [
          Operations.Employees1099Read.toString(),
          Operations.Employees1099StatusUpdate.toString(),
          Operations.Employees1099Delete.toString(),
          Operations.Employees1099Create.toString(),
          Operations.Employees1099Export.toString(),
          Operations.Employees1099Parameter.toString()
        ].indexOf(operation) > -1)) {
          if (!pwpMenu) {
            pwpMenu = this.buildPWPMenu(navItems);
          }
          pwpMenu.children.push({
            displayName: '1099 Workers',
            iconName: '',
            route: `${site}/piece-work-pay/1099-workers`,
            custom: false
          });
        }


        if (operations.find(operation => [
          Operations.InsuranceCertificatesRead.toString(),
          Operations.InsuranceCertificatesCreate.toString(),
          Operations.InsuranceCertificatesUpdate.toString(),
          Operations.InsuranceCertificatesDelete.toString(),
          Operations.InsuranceCertificatesAttachmentRead.toString(),
          Operations.InsuranceCertificatesAttachmentCreate.toString(),
          Operations.InsuranceCertificatesAttachmentDelete.toString(),
          Operations.InsuranceCertificatesParameter.toString()
        ].indexOf(operation) > -1)) {
          if (!pwpMenu) {
            pwpMenu = this.buildPWPMenu(navItems);
          }
          pwpMenu.children.push({
            displayName: 'Insurance Certificates',
            iconName: '',
            route: `${site}/piece-work-pay/insurance-certificates`,
            custom: false
          });
        }

        if (operations.find(operation => [
          Operations.Payroll1099Read.toString(),
          Operations.Payroll1099Export.toString()
        ].indexOf(operation) > -1)) {
          if (!pwpMenu) {
            pwpMenu = this.buildPWPMenu(navItems);
          }
          pwpMenu.children.push({
            displayName: '1099 Payroll',
            iconName: '',
            route: `${site}/piece-work-pay/1099-payroll`,
            custom: false
          });
        }

        }

        let buildingMenu: NavItem;
        if (!buildingMenu) {
          buildingMenu = this.buildBuildingMenu(navItems);
        }
        if (operations.find(operation => [
          Operations.ProductDefaultsRead.toString(),
          Operations.ProductDefaultsCreate.toString(),
          Operations.ProductDefaultsUpdate.toString(),
          Operations.ProductDefaultsDelete.toString(),
          Operations.ProductDefaultsDeactivate.toString(),
          Operations.ProductDefaultsBackgroundCfg.toString(),
        ].indexOf(operation) > -1)) {
          if (!buildingMenu) {
            buildingMenu = this.buildBuildingMenu(navItems);
          }
          buildingMenu.children.push({
            displayName: 'Product Defaults',
            iconName: '',
            custom: false,
            route: `${site}/building-designs/product-defaults`
          });
        }

        if (operations.find(operation => [
          Operations.BuildingPackageRead.toString(),
          Operations.BuildingPackageCreate.toString(),
          Operations.BuildingPackageUpdate.toString(),
          Operations.BuildingPackageDuplicate.toString(),
          Operations.BuildingPackageDelete.toString(),
          Operations.BuildingPackageDeactivate.toString(),
          Operations.QuoteCreate.toString(),
          Operations.BuildingPackageUpdateVariant.toString(),
          Operations.BuildingPackageUpdateStockReq.toString(),
          Operations.BuildingPackageBackgroundCfg.toString(),
          Operations.BuildingPackageUpdateGroups.toString(),
          Operations.BuildingPackageUpdatePackageName.toString(),
        ].indexOf(operation) > -1)) {
          buildingMenu.children.push({
            displayName: 'Building Packages',
            iconName: '',
            custom: false,
            route: `${site}/building-designs/building-packages`
          });
        }

        // Check CRUD and export operations on BPGroups
        if (operations.find(operation => [
          Operations.BPGroupsRead.toString(),
          Operations.BPGroupsCreate.toString(),
          Operations.BPGroupsUpdate.toString(),
          Operations.BPGroupsDelete.toString(),
        ].indexOf(operation) > -1)) {
          if (!buildingMenu) {
            buildingMenu = this.buildBuildingMenu(navItems);
          }
          buildingMenu.children.push({
            displayName: 'BP Groups',
            iconName: '',
            custom: false,
            route: `${site}/building-designs/bp-groups`
          });
        }
        
        if (operations.find(operation => [
          Operations.StockRequestsRead.toString(),
          Operations.StockRequestsUpdate.toString(),
          Operations.StockRequestsApprove.toString(),
          Operations.StockRequestsBuildingCreate.toString(),
          Operations.StockRequestsPrebuiltsCreate.toString(),
          Operations.StockRequestsDelete.toString(),
        ].indexOf(operation) > -1)) {
          navItems.push({
            displayName: 'Stock Requests',
            iconName: 'style-builder',
            route: `${site}/stock-requests`,
            kendoIcon: true,
            custom: false
          });
        }

        let resoucesMenu: NavItem;

        // Check CRUD operations on equipments
        if (operations.find(operation => [
          Operations.EquipmentRead.toString(),
          Operations.EquipmentCreate.toString(),
          Operations.EquipmentUpdate.toString(),
          Operations.EquipmentDelete.toString()
        ].indexOf(operation) > -1)) {
          if (!resoucesMenu) {
            resoucesMenu = this.buildResourcesMenu(navItems);
          }
          resoucesMenu.children.push({
            displayName: 'Equipment',
            iconName: '',
            route: `${site}/resources/equipment`,
            routeGroup: [
              `${site}/resources/equipment`,
              `${site}/resources/equipment/new`,
              `${site}/resources/equipment/edit`,
            ],
            custom: false
          });
        }

        // Check CRUD operations on equipmentdefaults
        if (operations.find(operation => [
          Operations.EquipmentDefaultRead.toString(),
          Operations.EquipmentDefaultCreate.toString()
        ].indexOf(operation) > -1)) {
          if (!resoucesMenu) {
            resoucesMenu = this.buildResourcesMenu(navItems);
          }
          resoucesMenu.children.push({
            displayName: 'Equipment Defaults',
            iconName: '',
            custom: false,
            route: `${site}/resources/equipment/default`
          });
        }

        // Check CRUD operations on oosperiods
        if (operations.find(operation => [
          Operations.OosPeriodRead.toString(),
          Operations.OosPeriodCreate.toString(),
          Operations.OosPeriodUpdate.toString(),
          Operations.OosPeriodDelete.toString()
        ].indexOf(operation) > -1)) {
          if (!resoucesMenu) {
            resoucesMenu = this.buildResourcesMenu(navItems);
          }
          resoucesMenu.children.push({
            displayName: 'Out of Service Periods',
            iconName: '',
            route: `${site}/service-periods`,
            routeGroup: [
              `${site}/service-periods`,
              `${site}/service-periods/new`,
              `${site}/service-periods/edit`,
            ],
            custom: false
          });
        }

        // Check CRUD operations on permit status
        if (operations.find(operation => [
          Operations.PermitStatusRead.toString(),
          Operations.PermitStatusCreate.toString(),
          Operations.PermitStatusUpdate.toString(),
          Operations.PermitStatusDelete.toString()
        ].indexOf(operation) > -1)) {
          if (!resoucesMenu) {
            resoucesMenu = this.buildResourcesMenu(navItems);
          }
          resoucesMenu.children.push({
            displayName: 'Permit Statuses',
            iconName: '',
            route: `${site}/permit-plan-status`,
            routeGroup: [
              `${site}/permit-plan-status`,
              `${site}/permit-plan-status/new`,
              `${site}/permit-plan-status/edit`,
            ],
            custom: false
          });
        }

        /* resoucesMenu.children.push({
          displayName: 'Merchants',
          iconName: '',
          kendoIcon: true,
          route: `${site}/merchants`,
          custom: false
        });

        resoucesMenu.children.push({
          displayName: 'Products',
          iconName: '',
          kendoIcon: true,
          route: `${site}/products`,
          custom: false
        });

        resoucesMenu.children.push({
          displayName: 'Stores',
          iconName: '',
          kendoIcon: true,
          route: `${site}/stores`,
          custom: false
        });
       */

        // Check CRUD operations on Template
        if (operations.find(operation => [
          Operations.TemplateRead.toString(),
          Operations.TemplateDelete.toString(),
          Operations.TemplateUpdate.toString(),
          Operations.TemplateCreate.toString(),
          Operations.TemplateImportExport.toString()
        ].indexOf(operation) > -1)) {
          if (!resoucesMenu) {
            resoucesMenu = this.buildResourcesMenu(navItems);
          }
          resoucesMenu.children.push({
            displayName: 'Templates',
            iconName: '',
            kendoIcon: true,
            route: `${site}/templates`,
            custom: false
          });
        }

        

        var adminMenu: NavItem;
        // Check CRUD operations on users
        if (operations.find(operation => [
          Operations.UserRead.toString(),
          Operations.UserCreate.toString(),
          Operations.UserUpdate.toString(),
          Operations.UserDelete.toString()
        ].indexOf(operation) > -1)) {
          if (!adminMenu) {
            adminMenu = this.buildAdminMenu(navItems);
          }
          adminMenu.children.push({
            displayName: 'Users',
            iconName: '',
            route: `${site}/users`,
            routeGroup: [
              `${site}/users`,
              `${site}/users/new`,
              `${site}/users/edit`,
            ],
            custom: false
          });
        }

        // Check CRUD operations on usergroups
        if (operations.find(operation => [
          Operations.UserGroupRead.toString(),
          Operations.UserGroupCreate.toString(),
          Operations.UserGroupUpdate.toString(),
          Operations.UserGroupDelete.toString()
        ].indexOf(operation) > -1)) {
          if (!adminMenu) {
            adminMenu = this.buildAdminMenu(navItems);
          }
          adminMenu.children.push({
            displayName: 'User Groups',
            iconName: '',
            route: `${site}/user-groups`,
            routeGroup: [
              `${site}/user-groups`,
              `${site}/user-groups/new`,
              `${site}/user-groups/edit`,
            ],
            custom: false
          });
        }

         // Check CRUD operations on CP usergroups
        /* if (
          operations.find(
            operation =>
              [
                Operations.UserGroupRead.toString(),
                Operations.UserGroupCreate.toString(),
                Operations.UserGroupUpdate.toString(),
                Operations.UserGroupDelete.toString()
              ].indexOf(operation) > -1
          )
        ) {
          if (!adminMenu) {
            adminMenu = this.buildAdminMenu(navItems);
          }
          adminMenu.children.push({
           displayName: 'Users CP',
            iconName: '',
            route: `${site}/users-cp`,
            routeGroup: [
              `${site}/users-cp`,
              `${site}/users-cp/new`,
              `${site}/users-cp/edit`,
            ],
            custom: false
          });
        }
        // Check CRUD operations on users cp
        if (operations.find(operation => [
          Operations.UserRead.toString(),
          Operations.UserCreate.toString(),
          Operations.UserUpdate.toString(),
          Operations.UserDelete.toString()
        ].indexOf(operation) > -1)) {
          if (!adminMenu) {
            adminMenu = this.buildAdminMenu(navItems);
          }
          adminMenu.children.push({
            displayName: 'CP User Groups',
            iconName: '',
            route: `${site}/user-groups-cp`,
            routeGroup: [`${site}/user-groups-cp`, `${site}/user-groups-cp/new`, `${site}/user-groups-cp/edit`],

            custom: false
          });
        } */

        if (!this.siteService.isHideFncBySite(site)) {
          // Get CP Link
          if (operations.find(operation => [
            Operations.OrderGetCPLink.toString()
          ].indexOf(operation) > -1)) {
            if (!adminMenu) {
              adminMenu = this.buildAdminMenu(navItems);
            }
            adminMenu.children.push({
              displayName: 'Customer Portal',
              iconName: '',
              route: `${site}/get-cp-link`,
              routeGroup: [
                `${site}/get-cp-link`
              ],
              custom: false
            });
          }
        }

        this.navItems = navItems;
      });
    });
  }

  ngOnDestroy() {
    this.operationsSuscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
    this.navService.closedToggle = this.closedToggle;
  }

  public closeNav() {
    this.navService.closeNav();
  }

  private buildPWPMenu(navItems: NavItem[]): NavItem {
    let pwpMenu = {
      displayName: 'PW Pay',
      iconName: 'file-data',
      route: '',
      custom: false,
      kendoIcon: true,
      children: []
    };
    navItems.push(pwpMenu);
    return pwpMenu;
  }

private buildBuildingMenu(navItems: NavItem[]): NavItem {
    let pwpMenu = {
      displayName: 'Building Designs',
      iconName: 'image-map-editor',
      route: '',
      custom: false,
      kendoIcon: true,
      children: []
    };
    navItems.push(pwpMenu);
    return pwpMenu;
  }

  private buildResourcesMenu(navItems: NavItem[]): NavItem {
    let resourcesMenu = {
      displayName: 'Resources',
      iconName: 'resource',
      route: '',
      custom: true,
      children: []
    };
    navItems.push(resourcesMenu);
    return resourcesMenu;
  }

  private buildAdminMenu(navItems: NavItem[]): NavItem {
    let adminMenu = {
      displayName: 'Administration',
      iconName: 'setting',
      route: '',
      custom: true,
      children: []
    };
    navItems.push(adminMenu);
    return adminMenu;
  }
}
