import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CompositeFilterDescriptorEx, FilterDescriptorEx } from '../ooms-filter-descriptor';
import { FilterOperatorComponent } from '../filter-operator.component';
import { FilterNullValueDescriptor } from './filter-null-value-descriptor';

@Component({
  selector: 'app-filter-null-value',
  templateUrl: './filter-null-value.component.html',
  styleUrls: ['./filter-null-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterNullValueComponent extends FilterOperatorComponent<FilterNullValueDescriptor> {
  readonly operators = [
    {
      text: 'Yes',
      value: 'Yes'
    },
    {
      text: 'No',
      value: 'No'
    }
  ];

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  init(): void {
    this.formGroup = this.fb.group({
      value: [this.defaultFilterValue(0), Validators.required]
    });
  }

  public get value(): FilterDescriptorEx | CompositeFilterDescriptorEx {
    const value = this.formGroup.get('value').value as string;
    if (value === 'Yes') {
      return this.descriptor.createFilter('ne', null);
    }
    return this.descriptor.createFilter('eq', null);
  }
}
