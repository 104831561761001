<kendo-dialog-titlebar>
  <span>{{params?.editMode ? 'Edit' : 'Add'}} a filter for {{params?.descriptors.text}}</span>
</kendo-dialog-titlebar>
<ng-container>
  <div class="toggle-btn" *ngIf="params?.descriptors.advancedDescriptor">
    <button kendoButton (click)="toggleMode()" fillMode="flat" themeColor="primary">{{advancedMode ? 'Default' : 'Advanced'}}</button>
  </div>

  <ng-template filter-template></ng-template>
</ng-container>
<kendo-dialog-actions layout="end">
  <div>
    <button kendoButton (click)="onConfirmAction()" [themeColor]="'primary'" [disabled]="!filterValid">Apply</button>
    <button kendoButton (click)="onCancelAction()">Cancel</button>
  </div>
</kendo-dialog-actions>
