import { ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FilterOperatorComponent } from '../filter-operator.component';
import { FilterStringDescriptor } from './filter-string-descriptor';
import { FilterDescriptorEx, CompositeFilterDescriptorEx } from '../ooms-filter-descriptor';

@Component({
  selector: 'filter-string',
  templateUrl: './filter-string.component.html',
  styleUrls: ['./filter-string.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterStringComponent extends FilterOperatorComponent<FilterStringDescriptor> {
  @ViewChild('valueInput') public valueInput: ElementRef;

  operators = [
    {
      text: 'Equals',
      value: 'eq'
    },
    {
      text: 'Does Not Equal',
      value: 'neq'
    },
    {
      disabled: true
    },
    {
      text: 'Begins With',
      value: 'startswith'
    },
    {
      text: 'Ends With',
      value: 'endswith'
    },
    {
      disabled: true
    },
    {
      text: 'Contains',
      value: 'contains'
    }
    // {
    //   text: 'Does Not Contain',
    //   value: 'doesnotcontain'
    // }
  ];

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  init(): void {
    if (this.descriptor.operators?.length > 0) {
      this.operators = this.descriptor.operators;
    }

    const operator = this.defaultOperator || (this.descriptor.isEnumType ? 'eq' : 'contains');

    this.formGroup = this.fb.group({
      operator: [operator, Validators.required],
      value: [this.defaultFilterValue(0), Validators.required]
    });
  }

  public get value(): FilterDescriptorEx | CompositeFilterDescriptorEx {
    const operator = this.formGroup.get('operator').value as string;
    const value = this.formGroup.get('value').value as string;
    return this.descriptor.createFilter(operator, this.descriptor.isEnumType ? value.toUpperCase() : value);
  }

  public changeOperator(op: string) {
    window.setTimeout(() => {
      this.valueInput.nativeElement.focus();
    });
  }
}
