import { FilterBaseComponent } from './filter-base.component';
import { Component, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { OOMSFilterDescriptor } from './ooms-filter-descriptor';

export interface OperatorType {
  text?: string;
  value?: string | boolean;
  disabled?: boolean;
}

@Directive()
export abstract class FilterOperatorComponent<
  TDescriptor extends OOMSFilterDescriptor
> extends FilterBaseComponent<TDescriptor> {
  readonly operators: OperatorType[];
  formGroup: UntypedFormGroup;

  get valid(): boolean {
    return !!this.formGroup ? this.formGroup.valid : false;
  }

  get defaultOperator() {
    if (!!this.defaultFilter) {
      return this.defaultFilter.operator;
    } else {
      return null;
    }
  }

  defaultFilterValue(index?: number) {
    if (!!this.defaultFilter) {
      if ('value' in this.defaultFilter) {
        return this.defaultFilter.value;
      } else if ('values' in this.defaultFilter) {
        if (this.defaultFilter.values.length > index) {
          return this.defaultFilter.values[index];
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  itemDisabled(item: { dataItem: OperatorType; index: number }) {
    return item.dataItem.disabled;
  }
}
