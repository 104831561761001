<kendo-dialog-titlebar
  class="k-window-titlebar k-dialog-titlebar oos-dialog"
  id="kendo-dialog-title-214711"
  
>
  <div class="k-window-title k-dialog-title">
    <div>
      <kendo-icon name="warning"></kendo-icon>&nbsp;Warning
    </div>
  </div>
</kendo-dialog-titlebar>
<div class="k-content k-window-content k-dialog-content"><p> Are you sure you want to schedule for out of service periods?</p></div>
