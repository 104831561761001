/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, Optional, Component, Input, ContentChild, NgModule } from '@angular/core';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { validatePackage } from '@progress/kendo-licensing';

/**
 * @hidden
 */
const _c0 = ["*"];
const packageMetadata = {
  name: '@progress/kendo-angular-pdf-export',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1731430625,
  version: '17.0.1',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};
class PDFExportTemplateDirective {
  templateRef;
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static ɵfac = function PDFExportTemplateDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PDFExportTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: PDFExportTemplateDirective,
    selectors: [["", "kendoPDFTemplate", ""]],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDFExportTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoPDFTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
const FIELDS = ['bottom', 'left', 'right', 'top'];
/**
 * Represents the Kendo UI PDFMargin component for Angular.
 */
class PDFExportMarginComponent {
  /**
   * The bottom margin. The supported units are `"mm"`, `"cm"`, `"in"`, and `"pt"` (default).
   * Numbers are considered to be points (`"pt"`).
   */
  left;
  /**
   * The top margin. The supported units are `"mm"`, `"cm"`, `"in"`, and `"pt"` (default).
   * Numbers are considered to be points (`"pt"`).
   */
  top;
  /**
   * The right margin. The supported units are `"mm"`, `"cm"`, `"in"`, and `"pt"` (default).
   * Numbers are considered to be points (`"pt"`).
   */
  right;
  /**
   * The bottom margin. The supported units are `"mm"`, `"cm"`, `"in"`, and `"pt"` (default).
   * Numbers are considered to be points (`"pt"`).
   */
  bottom;
  /**
   * @hidden
   */
  get options() {
    const options = {};
    for (let idx = 0; idx < FIELDS.length; idx++) {
      const field = FIELDS[idx];
      const value = this[field];
      if (typeof value !== 'undefined') {
        options[field] = value;
      }
    }
    return options;
  }
  static ɵfac = function PDFExportMarginComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PDFExportMarginComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PDFExportMarginComponent,
    selectors: [["kendo-pdf-export-margin"]],
    inputs: {
      left: "left",
      top: "top",
      right: "right",
      bottom: "bottom"
    },
    standalone: true,
    features: [i0.ɵɵStandaloneFeature],
    decls: 0,
    vars: 0,
    template: function PDFExportMarginComponent_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDFExportMarginComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-pdf-export-margin',
      template: ``,
      standalone: true
    }]
  }], null, {
    left: [{
      type: Input
    }],
    top: [{
      type: Input
    }],
    right: [{
      type: Input
    }],
    bottom: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
const compileTemplate = templateRef => {
  const context = {};
  let embeddedView = templateRef.createEmbeddedView(context);
  const result = data => {
    Object.assign(context, data);
    embeddedView.detectChanges();
    const templateWrap = document.createElement('span');
    embeddedView.rootNodes.forEach(rootNode => {
      templateWrap.appendChild(rootNode.cloneNode(true));
    });
    return templateWrap;
  };
  result.destroy = () => {
    embeddedView.destroy();
    embeddedView = null;
  };
  return result;
};

/**
 * Represents the [Kendo UI PDF Export component for Angular]({% slug overview_pdfexport %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *     <div class="example-config">
 *       <button kendoButton (click)="pdf.saveAs('document.pdf')">
 *         Save As PDF...
 *       </button>
 *     </div>
 *
 *     <kendo-pdf-export #pdf paperSize="A4" margin="2cm">
 *       Content goes here
 *     </kendo-pdf-export>
 *   `
 * })
 * export class AppComponent {
 * }
 * ```
 */
class PDFExportComponent {
  element;
  /**
   * Specifies if the Print dialog should be opened immediately after loading the document ([see example](slug:autoprint_pdfexport)).
   * Requires `@progress/kendo-drawing` v1.9.0 or later.
   * @default false
   */
  autoPrint;
  /**
   * The author (metadata) of the PDF document.
   */
  author;
  /**
   * A flag that indicates whether to produce actual hyperlinks in the exported PDF file ([see example](slug:hyperlinks_pdfexport)).
   * It is also possible to set a CSS selector. All matching links will be ignored.
   */
  avoidLinks;
  /**
   * An optional CSS selector that specifies the elements which cause the page breaks
   * ([see example]({% slug multipagecontent_pdfexport %}#toc-manual-page-breaking)).
   */
  forcePageBreak;
  /**
   * An optional CSS selector that specifies the elements which should not be split across the pages
   * ([see example]({% slug multipagecontent_pdfexport %}#toc-preventing-page-breaking-in-elements)).
   */
  keepTogether;
  /**
   * The creator of the PDF document.
   * @default "Kendo UI PDF Generator"
   */
  creator = 'Kendo UI PDF Generator';
  /**
   * The date when the PDF document is created. Defaults to `new Date()`.
   */
  date;
  /**
   * The forced resolution of the images in the exported PDF document
   * ([see example]({% slug embeddedimages_pdfexport %})).
   * By default, the images are exported at their full resolution.
   */
  imageResolution;
  /**
   * Specifies the name of the exported PDF file.
   * @default "export.pdf"
   */
  fileName = 'export.pdf';
  /**
   * If set to `true`, the content is forwarded to `proxyURL` even if the
   * browser supports local saving of files.
   */
  forceProxy;
  /**
   * The keywords (metadata) of the PDF document.
   */
  keywords;
  /**
   * A flag that indicates if the page will be in a landscape orientation.
   * By default, the page is in a portrait orientation.
   *
   * @default false
   */
  landscape;
  /**
   * Specifies the margins of the page. Numbers are treated as points (`"pt"`).
   *
   */
  margin;
  /**
   * Specifies the paper size of the PDF document ([see example]({% slug multipagecontent_pdfexport %}#toc-automatic-page-breaking)).
   * Defaults to `"auto"` which means that the paper size is determined by the content.
   * The size of the content in pixels matches the size of the output in points (1 pixel = 1/72 inch).
   * If `paperSize` is set, the content is split across multiple pages. This enables the `repeatHeaders` and
   * `scale` options, and allows you to specify a template.
   *
   * The paper size can be a `PaperSize`, an array of two numbers which specify the width and height in points (1 pt = 1/72 in), or an
   * array of two strings which specify the width and height in units. The supported units are `"mm"`, `"cm"`, `"in"`, and `"pt"`.
   * @default 'auto'
   */
  paperSize;
  /**
   * Specifies if the `<thead>` elements of the tables will be repeated on each page
   * ([see example]({% slug recurrenttableheaders_pdfexport %})).
   */
  repeatHeaders;
  /**
   * A scale factor ([see example]({% slug scalingofcontent_pdfexport %})).
   * The text size on the screen might be too big for printing.
   * To scale down the output in PDF, use this option.
   *
   * @default 1
   */
  scale;
  /**
   * A key/value dictionary of form values which will be sent to the proxy.
   * Can be used to submit Anti-Forgery tokens and other metadata.
   */
  proxyData;
  /**
   * The URL of the server-side proxy which streams the file to the end user. You need to use a proxy if
   * the browser is not capable of saving files locally.
   * It is your responsibility to implement the server-side proxy. The proxy returns the decoded file with
   * the `"Content-Disposition"` header set to `attachment; filename="<fileName.pdf>"`.
   *
   * In the request body, the proxy receives a POST request with the specific parameters.
   * [See example](slug:server_proxy#toc-implementations).
   */
  proxyURL;
  /**
   * A name or keyword which indicates where to display the document that is returned from the proxy.
   * To display the document in a new window or iframe,
   * the proxy has to have the `"Content-Disposition"` header set to `inline; filename="<fileName.pdf>"`.
   * @default "_self"
   */
  proxyTarget;
  /**
   * The producer (metadata) of the PDF document.
   */
  producer;
  /**
   * The subject (metadata) of the PDF document.
   */
  subject;
  /**
   * The title (metadata) of the PDF document.
   */
  title;
  /**
   * @hidden
   */
  pageTemplateDirective;
  /**
   * @hidden
   */
  marginComponent;
  get drawMargin() {
    const marginComponent = this.marginComponent;
    let margin = this.margin;
    if (marginComponent) {
      margin = Object.assign(margin || {}, marginComponent.options);
    }
    return margin;
  }
  pageTemplate;
  constructor(element) {
    this.element = element;
    validatePackage(packageMetadata);
  }
  /**
   * Saves the content as a PDF file with the specified name.
   * @param fileName - The name of the exported file.
   */
  saveAs(fileName = this.fileName) {
    this.save(this.element.nativeElement, fileName);
  }
  /**
   * Exports the content as a `Group` for further processing.
   *
   * @return The root group of the exported scene.
   */
  export() {
    return this.exportElement(this.element.nativeElement);
  }
  save(element, fileName) {
    this.exportElement(element).then(group => this.exportGroup(group, this.pdfOptions())).then(dataUri => this.saveDataUri(dataUri, fileName, this.saveOptions()));
  }
  exportElement(element) {
    const promise = this.drawElement(element, this.drawOptions());
    const cleanup = this.cleanup.bind(this);
    promise.then(cleanup, cleanup);
    return promise;
  }
  cleanup() {
    if (this.pageTemplate) {
      this.pageTemplate.destroy();
      delete this.pageTemplate;
    }
  }
  drawOptions() {
    if (this.pageTemplateDirective) {
      this.pageTemplate = compileTemplate(this.pageTemplateDirective.templateRef);
    }
    return {
      avoidLinks: this.avoidLinks,
      forcePageBreak: this.forcePageBreak,
      keepTogether: this.keepTogether,
      margin: this.drawMargin,
      paperSize: this.paperSize,
      landscape: this.landscape,
      repeatHeaders: this.repeatHeaders,
      scale: this.scale,
      template: this.pageTemplate
    };
  }
  pdfOptions() {
    return {
      autoPrint: this.autoPrint,
      author: this.author,
      creator: this.creator,
      date: this.date,
      imgDPI: this.imageResolution,
      keywords: this.keywords,
      landscape: this.landscape,
      margin: this.drawMargin,
      multiPage: true,
      paperSize: this.paperSize,
      producer: this.producer,
      subject: this.subject,
      title: this.title
    };
  }
  saveOptions() {
    return {
      forceProxy: this.forceProxy,
      proxyData: this.proxyData,
      proxyTarget: this.proxyTarget,
      proxyURL: this.proxyURL
    };
  }
  drawElement(element, options) {
    return drawDOM(element, options);
  }
  exportGroup(group, options) {
    return exportPDF(group, options);
  }
  saveDataUri(dataUri, fileName, options) {
    saveAs(dataUri, fileName, options);
  }
  static ɵfac = function PDFExportComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PDFExportComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PDFExportComponent,
    selectors: [["kendo-pdf-export"]],
    contentQueries: function PDFExportComponent_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PDFExportTemplateDirective, 5);
        i0.ɵɵcontentQuery(dirIndex, PDFExportMarginComponent, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.pageTemplateDirective = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.marginComponent = _t.first);
      }
    },
    inputs: {
      autoPrint: "autoPrint",
      author: "author",
      avoidLinks: "avoidLinks",
      forcePageBreak: "forcePageBreak",
      keepTogether: "keepTogether",
      creator: "creator",
      date: "date",
      imageResolution: "imageResolution",
      fileName: "fileName",
      forceProxy: "forceProxy",
      keywords: "keywords",
      landscape: "landscape",
      margin: "margin",
      paperSize: "paperSize",
      repeatHeaders: "repeatHeaders",
      scale: "scale",
      proxyData: "proxyData",
      proxyURL: "proxyURL",
      proxyTarget: "proxyTarget",
      producer: "producer",
      subject: "subject",
      title: "title"
    },
    standalone: true,
    features: [i0.ɵɵStandaloneFeature],
    ngContentSelectors: _c0,
    decls: 2,
    vars: 0,
    template: function PDFExportComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div");
        i0.ɵɵprojection(1);
        i0.ɵɵelementEnd();
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDFExportComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-pdf-export',
      template: `<div><ng-content></ng-content></div>`,
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    autoPrint: [{
      type: Input
    }],
    author: [{
      type: Input
    }],
    avoidLinks: [{
      type: Input
    }],
    forcePageBreak: [{
      type: Input
    }],
    keepTogether: [{
      type: Input
    }],
    creator: [{
      type: Input
    }],
    date: [{
      type: Input
    }],
    imageResolution: [{
      type: Input
    }],
    fileName: [{
      type: Input
    }],
    forceProxy: [{
      type: Input
    }],
    keywords: [{
      type: Input
    }],
    landscape: [{
      type: Input
    }],
    margin: [{
      type: Input
    }],
    paperSize: [{
      type: Input
    }],
    repeatHeaders: [{
      type: Input
    }],
    scale: [{
      type: Input
    }],
    proxyData: [{
      type: Input
    }],
    proxyURL: [{
      type: Input
    }],
    proxyTarget: [{
      type: Input
    }],
    producer: [{
      type: Input
    }],
    subject: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    pageTemplateDirective: [{
      type: ContentChild,
      args: [PDFExportTemplateDirective, {
        static: false
      }]
    }],
    marginComponent: [{
      type: ContentChild,
      args: [PDFExportMarginComponent, {
        static: false
      }]
    }]
  });
})();

/**
 * Utility array that contains all `PDFExport` related components and directives
 */
const KENDO_PDFEXPORT = [PDFExportComponent, PDFExportMarginComponent, PDFExportTemplateDirective];

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi']) definition for the PDF Export directive.
 */
class PDFExportModule {
  static ɵfac = function PDFExportModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PDFExportModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: PDFExportModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDFExportModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_PDFEXPORT],
      exports: [...KENDO_PDFEXPORT]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { KENDO_PDFEXPORT, PDFExportComponent, PDFExportMarginComponent, PDFExportModule, PDFExportTemplateDirective, compileTemplate };