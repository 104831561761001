/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { forwardRef, Directive, Input, EventEmitter, ViewContainerRef, Component, ViewChild, HostBinding, TemplateRef, InjectionToken, ApplicationRef, Injectable, Inject, Optional, NgModule } from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import * as i1$1 from '@angular/animations';
import { style, animate } from '@angular/animations';
import { take } from 'rxjs/operators';
import { validatePackage } from '@progress/kendo-licensing';
import * as i1 from '@progress/kendo-angular-l10n';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { xIcon, checkOutlineIcon, exclamationCircleIcon, xOutlineIcon, infoCircleIcon } from '@progress/kendo-svg-icons';
import { guid } from '@progress/kendo-angular-common';
import { IconWrapperComponent, IconsService } from '@progress/kendo-angular-icons';
const _c0 = ["container"];
function NotificationComponent_kendo_icon_wrapper_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-icon-wrapper", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("name", ctx_r0.typeIconClass())("svgIcon", ctx_r0.typeSVGIcon());
  }
}
function NotificationComponent_ng_template_4_Template(rf, ctx) {}
function NotificationComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.templateString, " ");
  }
}
function NotificationComponent_span_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 9)(1, "span", 10);
    i0.ɵɵlistener("click", function NotificationComponent_span_8_Template_span_click_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.onCloseClick());
    });
    i0.ɵɵelement(2, "kendo-icon-wrapper", 11);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", ctx_r0.closeButtonTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("svgIcon", ctx_r0.xIcon);
  }
}
const _c1 = ["group"];
class NotificationSettings {
  /**
   * Defines the content of the Notification.
   */
  content;
  /**
   * Specifies the time in milliseconds after which the
   * Notification will hide
   * ([see example](slug:hiding_notifications#toc-defining-a-delay-before-hiding)).
   * Defaults to `5000`.
   */
  hideAfter = 5000;
  /**
   * Defines the position of the Notification
   * ([see example]({% slug positioning_notification %})).
   *
   * The possible values are:
   * * `horizontal: 'left'|'center'|'right'`
   * * `vertical: 'top'|'bottom'`
   */
  position = {
    horizontal: 'right',
    vertical: 'top'
  };
  /**
   * Specifies the animation settings of the Notification
   * ([see example]({% slug animations_notification %})).
   *
   * The possible values are:
   * * `duration`&mdash;Accepts a number in milliseconds. Defaults to `500ms`.
   * * `type?: 'slide'| (Default) 'fade'`
   */
  animation = {
    type: 'fade',
    duration: 500
  };
  /**
   * Specifies if the Notification will require a user action to hide.
   * If the property is set to `true`, the Notification renders a **Close** button
   * ([see example]({% slug hiding_notifications %}#toc-defining-a-closable-notification)).
   *
   * The possible values are:
   * * (Default) `false`
   * * `true`
   */
  closable = false;
  /**
   * Specifies the title of the close button.
   */
  closeTitle;
  /**
   * Specifies the type of the Notification
   * ([see example]({% slug types_notification %})).
   *
   * The possible values are:
   * * `style: (Default) 'none'|'success'|'error'|'warning'|'info'`
   * * `icon: 'true'|'false'`
   */
  type = {
    style: 'none',
    icon: true
  };
  /**
   * Specifies the width of the Notification.
   */
  width;
  /**
   * Specifies the height of the Notification.
   */
  height;
  /**
   * The value of the Notification element `aria-label` attribute.
   * @default 'Notification'
   */
  notificationLabel = 'Notification';
  /**
   * Specifies a list of CSS classes that will be added to the Notification.
   * To apply CSS rules to the component, set `encapsulation` to `ViewEncapsulation.None`
   * ([see example](slug:overview_notification)).
   *
   * > To style the content of the Notification, use the `cssClass` property binding.
   */
  cssClass;
  /**
   * Defines the container to which the Notification will be appended
   * ([see example]({% slug dynamic_containers %})).
   *
   * If not provided, the Notification will be placed in the root component
   * of the application or in the `body` element of the document.
   */
  appendTo;
}

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-notification',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1731430880,
  version: '17.0.1',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * @hidden
 */
function slideAnimation(height, duration) {
  return [style({
    overflow: 'hidden',
    height: 0
  }), animate(`${duration}ms ease-in`, style({
    height: `${height}px`
  }))];
}
/**
 * @hidden
 */
function slideCloseAnimation(height, duration) {
  return [style({
    height: `${height}px`
  }), animate(`${duration}ms ease-in`, style({
    overflow: 'hidden',
    height: 0
  }))];
}
/**
 * @hidden
 */
function fadeAnimation(duration) {
  return [style({
    opacity: 0
  }), animate(`${duration}ms ease-in`, style({
    opacity: 1
  }))];
}
/**
 * @hidden
 */
function fadeCloseAnimation(duration) {
  return [style({
    opacity: 1
  }), animate(`${duration}ms ease-in`, style({
    opacity: 0
  }))];
}

/**
 * @hidden
 */
class LocalizedMessagesDirective extends ComponentMessages {
  service;
  /**
   * The title of the close button.
   */
  closeTitle;
  constructor(service) {
    super();
    this.service = service;
  }
  static ɵfac = function LocalizedMessagesDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LocalizedMessagesDirective)(i0.ɵɵdirectiveInject(i1.LocalizationService));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: LocalizedMessagesDirective,
    selectors: [["", "kendoNotificationLocalizedMessages", ""]],
    inputs: {
      closeTitle: "closeTitle"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([{
      provide: ComponentMessages,
      useExisting: forwardRef(() => LocalizedMessagesDirective)
    }]), i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizedMessagesDirective, [{
    type: Directive,
    args: [{
      providers: [{
        provide: ComponentMessages,
        useExisting: forwardRef(() => LocalizedMessagesDirective)
      }],
      selector: `[kendoNotificationLocalizedMessages]`,
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, {
    closeTitle: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 *
 */
class NotificationComponent {
  cdr;
  element;
  renderer;
  builder;
  localizationService;
  /**
   * @hidden
   */
  xIcon = xIcon;
  contentId = `k-${guid()}`;
  container;
  closeClickSubscription;
  close = new EventEmitter();
  templateRef;
  templateString;
  width = null;
  height = null;
  notificationLabel = 'Notification';
  cssClass;
  hideAfter;
  closable;
  type;
  animation;
  closeTitle;
  /**
   * @hidden
   */
  direction;
  get closeButtonTitle() {
    return this.closeTitle || this.localizationService.get('closeTitle');
  }
  defaultHideAfter = 5000;
  hideTimeout;
  animationEnd = new EventEmitter();
  dynamicRTLSubscription;
  rtl = false;
  constructor(cdr, element, renderer, builder, localizationService) {
    this.cdr = cdr;
    this.element = element;
    this.renderer = renderer;
    this.builder = builder;
    this.localizationService = localizationService;
    validatePackage(packageMetadata);
    this.dynamicRTLSubscription = localizationService.changes.subscribe(({
      rtl
    }) => {
      this.rtl = rtl;
      this.direction = this.rtl ? 'rtl' : 'ltr';
    });
  }
  notificationClasses() {
    return `${this.type ? this.typeClass() : ''}
            ${this.closable ? 'k-notification-closable' : ''}`;
  }
  ngOnInit() {
    clearTimeout(this.hideTimeout);
  }
  ngOnDestroy() {
    clearTimeout(this.hideTimeout);
    if (this.dynamicRTLSubscription) {
      this.dynamicRTLSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    if (!this.closable && !this.animation) {
      this.setHideTimeout();
    }
    if (!this.closable && this.animation) {
      this.animationEnd.pipe(take(1)).subscribe(() => this.setHideTimeout());
    }
    if (this.animation) {
      this.animate(this.animation);
    }
  }
  typeClass() {
    return {
      'none': '',
      'success': 'k-notification-success',
      'warning': 'k-notification-warning',
      'error': 'k-notification-error',
      'info': 'k-notification-info'
    }[this.type.style];
  }
  typeIconClass() {
    return {
      'none': '',
      'success': 'check-outline',
      'warning': 'exclamation-circle',
      'error': 'x-outline',
      'info': 'info-circle'
    }[this.type.style];
  }
  typeSVGIcon() {
    return {
      'none': null,
      'success': checkOutlineIcon,
      'warning': exclamationCircleIcon,
      'error': xOutlineIcon,
      'info': infoCircleIcon
    }[this.type.style];
  }
  onCloseClick() {
    clearTimeout(this.hideTimeout);
    this.hide();
  }
  hide(customComponent) {
    const elementHeight = getComputedStyle(this.element.nativeElement).height;
    if (this.animation && elementHeight) {
      this.animate(this.animation, true);
      this.animationEnd.subscribe(() => {
        this.emitClose(customComponent);
      });
      return;
    }
    this.emitClose(customComponent);
  }
  setHideTimeout() {
    const hideAfter = this.hideAfter || this.defaultHideAfter;
    this.hideTimeout = window.setTimeout(() => this.onCloseClick(), hideAfter);
  }
  emitClose(customComponent) {
    if (customComponent) {
      customComponent.destroy();
    }
    this.close.emit();
  }
  play(animation, animatedElement) {
    const factory = this.builder.build(animation);
    const element = this.element.nativeElement;
    this.renderer.addClass(element, 'k-notification-container-animating');
    let player = factory.create(animatedElement);
    player.onDone(() => {
      this.renderer.removeClass(element, 'k-notification-container-animating');
      this.animationEnd.emit();
      if (player) {
        player.destroy();
        player = null;
      }
    });
    player.play();
  }
  animate(animation, onclose) {
    const element = this.element.nativeElement;
    const duration = animation.duration;
    const height = element.offsetHeight;
    const generatedAnimation = animation.type === 'slide' ? this.slideAnimation(height, duration, onclose) : this.fadeAnimation(duration, onclose);
    this.play(generatedAnimation, element);
  }
  slideAnimation(height, duration, onclose) {
    return onclose ? slideCloseAnimation(height, duration) : slideAnimation(height, duration);
  }
  fadeAnimation(duration, onclose) {
    return onclose ? fadeCloseAnimation(duration) : fadeAnimation(duration);
  }
  static ɵfac = function NotificationComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NotificationComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1$1.AnimationBuilder), i0.ɵɵdirectiveInject(i1.LocalizationService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NotificationComponent,
    selectors: [["kendo-notification"]],
    viewQuery: function NotificationComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 7, ViewContainerRef);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
      }
    },
    hostVars: 1,
    hostBindings: function NotificationComponent_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵattribute("dir", ctx.direction);
      }
    },
    inputs: {
      templateRef: "templateRef",
      templateString: "templateString",
      width: "width",
      height: "height",
      notificationLabel: "notificationLabel",
      cssClass: "cssClass",
      hideAfter: "hideAfter",
      closable: "closable",
      type: "type",
      animation: "animation"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([LocalizationService, {
      provide: L10N_PREFIX,
      useValue: 'kendo.notification'
    }]), i0.ɵɵStandaloneFeature],
    decls: 9,
    vars: 16,
    consts: () => {
      let i18n_0;
      if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
        /**
         * @desc The title of the close button
         * @meaning kendo.notification.closeTitle
         */
        const MSG__SPA_NODE_MODULES__PROGRESS_KENDO_ANGULAR_NOTIFICATION_FESM2022_PROGRESS_KENDO_ANGULAR_NOTIFICATION_MJS_0 = goog.getMsg("Close");
        i18n_0 = MSG__SPA_NODE_MODULES__PROGRESS_KENDO_ANGULAR_NOTIFICATION_FESM2022_PROGRESS_KENDO_ANGULAR_NOTIFICATION_MJS_0;
      } else {
        i18n_0 = $localize`:kendo.notification.closeTitle|The title of the close button:Close`;
      }
      return [["container", ""], ["kendoNotificationLocalizedMessages", "", "closeTitle", i18n_0], ["role", "alert", "aria-live", "polite", 3, "ngClass"], ["innerCssClass", "k-notification-status", 3, "name", "svgIcon", 4, "ngIf"], [1, "k-notification-content", 3, "id"], [3, "ngIf", "ngTemplateOutlet"], [3, "ngIf"], ["class", "k-notification-actions", "aria-hidden", "true", 4, "ngIf"], ["innerCssClass", "k-notification-status", 3, "name", "svgIcon"], ["aria-hidden", "true", 1, "k-notification-actions"], [1, "k-notification-action", "k-notification-close-action", 3, "click"], ["name", "x", 3, "svgIcon"]];
    },
    template: function NotificationComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementContainer(0, 1);
        i0.ɵɵelementStart(1, "div", 2);
        i0.ɵɵtemplate(2, NotificationComponent_kendo_icon_wrapper_2_Template, 1, 2, "kendo-icon-wrapper", 3);
        i0.ɵɵelementStart(3, "div", 4);
        i0.ɵɵtemplate(4, NotificationComponent_ng_template_4_Template, 0, 0, "ng-template", 5)(5, NotificationComponent_ng_template_5_Template, 1, 1, "ng-template", 6);
        i0.ɵɵelementContainer(6, null, 0);
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(8, NotificationComponent_span_8_Template, 3, 2, "span", 7);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵclassMapInterpolate1("k-notification k-notification-base ", ctx.notificationClasses(), "");
        i0.ɵɵstyleProp("height", ctx.height, "px")("width", ctx.width, "px");
        i0.ɵɵproperty("ngClass", ctx.cssClass);
        i0.ɵɵattribute("aria-describedby", ctx.contentId)("aria-label", ctx.notificationLabel);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.type && ctx.type.icon && ctx.type.style !== "none");
        i0.ɵɵadvance();
        i0.ɵɵproperty("id", ctx.contentId);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.templateRef)("ngTemplateOutlet", ctx.templateRef);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.templateString);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.closable);
      }
    },
    dependencies: [LocalizedMessagesDirective, NgClass, NgIf, IconWrapperComponent, NgTemplateOutlet],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-notification',
      template: `
    <ng-container kendoNotificationLocalizedMessages
        i18n-closeTitle="kendo.notification.closeTitle|The title of the close button"
        closeTitle="Close"
    >
    </ng-container>
    <div class="k-notification k-notification-base {{ notificationClasses() }}"
        [ngClass]="cssClass"
        [style.height.px]="height"
        [style.width.px]="width"
        role="alert"
        aria-live="polite"
        [attr.aria-describedby]="contentId"
        [attr.aria-label]="notificationLabel">
        <kendo-icon-wrapper
            *ngIf="type && type.icon && type.style !== 'none'"
            innerCssClass="k-notification-status"
            [name]="typeIconClass()"
            [svgIcon]="typeSVGIcon()"
            >
        </kendo-icon-wrapper>
        <div [id]="contentId" class="k-notification-content">
            <ng-template
                [ngIf]="templateRef"
                [ngTemplateOutlet]="templateRef">
            </ng-template>
            <ng-template
                [ngIf]="templateString">
                {{ templateString }}
            </ng-template>
            <ng-container #container></ng-container>
        </div>

        <span *ngIf="closable" class="k-notification-actions" aria-hidden="true">
            <span class="k-notification-action k-notification-close-action" [attr.title]="closeButtonTitle" (click)="onCloseClick()">
                <kendo-icon-wrapper name="x" [svgIcon]="xIcon"></kendo-icon-wrapper>
            </span>
        </span>
    </div>
  `,
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.notification'
      }],
      standalone: true,
      imports: [LocalizedMessagesDirective, NgClass, NgIf, IconWrapperComponent, NgTemplateOutlet]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i1$1.AnimationBuilder
    }, {
      type: i1.LocalizationService
    }];
  }, {
    container: [{
      type: ViewChild,
      args: ['container', {
        read: ViewContainerRef,
        static: true
      }]
    }],
    templateRef: [{
      type: Input
    }],
    templateString: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    notificationLabel: [{
      type: Input
    }],
    cssClass: [{
      type: Input
    }],
    hideAfter: [{
      type: Input
    }],
    closable: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }]
  });
})();

/**
 * @hidden
 */
class NotificationContainerComponent {
  element;
  renderer;
  resolver;
  ngZone;
  container;
  group;
  id = '';
  notifications = [];
  position;
  constructor(element, renderer, resolver, ngZone) {
    this.element = element;
    this.renderer = renderer;
    this.resolver = resolver;
    this.ngZone = ngZone;
    /**/
  }
  ngOnDestroy() {
    this.notifications.forEach(notification => {
      if (notification.closeClickSubscription) {
        notification.closeClickSubscription.unsubscribe();
      }
    });
    this.notifications = [];
  }
  addNotification(settings) {
    this.position = settings.position;
    this.id = `${this.position.horizontal} ${this.position.vertical}`;
    const factory = this.resolver.resolveComponentFactory(NotificationComponent);
    const notificationRef = this.container.createComponent(factory);
    this.applySettings(notificationRef, settings);
    let customComponent = null;
    if (typeof settings.content === 'function') {
      const customFactory = this.resolver.resolveComponentFactory(settings.content);
      customComponent = notificationRef.instance.container.createComponent(customFactory);
    }
    notificationRef.changeDetectorRef.detectChanges();
    this.notifications.push(notificationRef.instance);
    if (settings.appendTo) {
      this.applyAbsolutePosition(settings.appendTo);
    }
    this.applyPosition();
    this.applyContainerWrap();
    return {
      afterHide: notificationRef.instance.close,
      hide: () => notificationRef.instance.hide(customComponent),
      notification: notificationRef,
      content: customComponent || null
    };
  }
  hide(notificationRef) {
    const instance = notificationRef.instance;
    const index = this.notifications.indexOf(instance);
    this.notifications.splice(index, 1);
    if (instance.closeClickSubscription) {
      instance.closeClickSubscription.unsubscribe();
    }
    instance.templateRef = null;
    instance.templateString = null;
    notificationRef.destroy();
    if (this.notifications.length > 0) {
      this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
        this.applyPosition();
      });
    }
  }
  applyContainerWrap() {
    const value = this.position.horizontal === 'right' ? 'wrap-reverse' : 'wrap';
    this.renderer.setStyle(this.group.nativeElement, 'flex-wrap', value);
  }
  applySettings(notificationRef, settings) {
    const notification = notificationRef.instance;
    const content = settings.content;
    const animation = settings.animation || null;
    notification.closeClickSubscription = notification.close.subscribe(() => this.hide(notificationRef));
    if (typeof content === 'string') {
      notification.templateString = content;
    }
    if (content instanceof TemplateRef) {
      notification.templateRef = content;
    }
    notification.animation = animation;
    const type = settings.type;
    if (type && type.style === undefined) {
      type.style = 'none';
    }
    if (type && type.icon === undefined) {
      type.icon = true;
    }
    notification.type = type;
    notification.closeTitle = settings.closeTitle;
    if (settings.cssClass) {
      notification.cssClass = settings.cssClass;
    }
    notification.closable = settings.closable;
    notification.hideAfter = settings.hideAfter;
    notification.width = settings.width;
    notification.height = settings.height;
  }
  applyAbsolutePosition(appendToContainer) {
    const appendTo = appendToContainer.element.nativeElement;
    const el = this.element.nativeElement.children[0];
    if (window.getComputedStyle(appendTo).position === 'static') {
      this.renderer.setStyle(appendTo, 'position', 'relative');
    }
    this.renderer.setStyle(el, 'position', 'absolute');
  }
  applyPosition() {
    const element = this.element.nativeElement.children[0];
    const elementHalfWidth = element.getBoundingClientRect().width / 2;
    const positionStyles = this.setContainerPosition(this.position, elementHalfWidth);
    Object.keys(positionStyles).forEach(cssStyle => {
      element.style[cssStyle] = positionStyles[cssStyle];
    });
  }
  setContainerPosition(position, offsetMargin) {
    const positionLayout = {
      horizontal: {
        left: {
          left: 0,
          alignItems: 'flex-start'
        },
        right: {
          right: 0,
          alignItems: 'flex-start'
        },
        center: {
          left: '50%',
          marginLeft: `${-offsetMargin}px`,
          alignItems: 'center'
        }
      },
      vertical: {
        top: {
          top: 0
        },
        bottom: {
          bottom: 0
        }
      }
    };
    const horizontal = positionLayout.horizontal[position.horizontal];
    const vertical = positionLayout.vertical[position.vertical];
    return Object.assign({}, horizontal, vertical);
  }
  static ɵfac = function NotificationContainerComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NotificationContainerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ComponentFactoryResolver), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NotificationContainerComponent,
    selectors: [["kendo-notification-container"]],
    viewQuery: function NotificationContainerComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 7, ViewContainerRef);
        i0.ɵɵviewQuery(_c1, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.group = _t.first);
      }
    },
    inputs: {
      id: "id"
    },
    standalone: true,
    features: [i0.ɵɵStandaloneFeature],
    decls: 4,
    vars: 0,
    consts: [["group", ""], ["container", ""], [1, "k-notification-group"]],
    template: function NotificationContainerComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 2, 0);
        i0.ɵɵelementContainer(2, null, 1);
        i0.ɵɵelementEnd();
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationContainerComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-notification-container',
      template: `
        <div #group class="k-notification-group">
            <ng-container #container></ng-container>
        </div>
    `,
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ComponentFactoryResolver
    }, {
      type: i0.NgZone
    }];
  }, {
    container: [{
      type: ViewChild,
      args: ['container', {
        read: ViewContainerRef,
        static: true
      }]
    }],
    group: [{
      type: ViewChild,
      args: ['group', {
        static: true
      }]
    }],
    id: [{
      type: Input
    }]
  });
})();

/**
 * Used to inject the Notification container. If not provided, the first root component of
 * the application is used.
 *
 * > The `NOTIFICATION_CONTAINER` can be used only with the [`NotificationService`]({% slug api_notification_notificationservice %}) class.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Notification module
 * import { NotificationModule, NOTIFICATION_CONTAINER } from '@progress/kendo-angular-notification';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { ElementRef, NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, NotificationModule], // import Notification module
 *     bootstrap:    [AppComponent],
 *     providers: [{
 *       provide: NOTIFICATION_CONTAINER,
 *       useFactory: () => {
 *          //return the container ElementRef, where the notification will be injected
 *          return { nativeElement: document.body } as ElementRef;
 *       }
 *     }]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 * ```
 */
const NOTIFICATION_CONTAINER = new InjectionToken('Notification Container');
/**
 * A service for opening Notification components dynamically
 * ([see example]({% slug overview_notification %})).
 *
 * @export
 * @class NotificationService
 */
class NotificationService {
  resolver;
  injector;
  container;
  notificationContainers = [];
  position = {
    horizontal: 'right',
    vertical: 'top'
  };
  applicationRef;
  /**
   * @hidden
   */
  constructor(resolver, injector, container) {
    this.resolver = resolver;
    this.injector = injector;
    this.container = container;
  }
  /**
   * Opens a Notification component. Created Notification are mounted
   * in the DOM directly in the root application component.
   *
   * @param {NotificationSettings} settings - The settings which define the Notification.
   *
   * @returns {NotificationRef} - A reference to the Notification object and the convenience properties.
   */
  show(settings) {
    if (!settings) {
      throw new Error('NotificationSettings settings are required');
    }
    const target = this.findGroupContainer(settings);
    const position = settings.position || this.position;
    const currentId = `${position.horizontal} ${position.vertical}`;
    let container;
    let notificationRef;
    let notificationContainer = this.notificationContainers.find(c => target.nativeElement.contains(c.element.nativeElement) && c.id === currentId);
    if (!notificationContainer) {
      container = this.resolver.resolveComponentFactory(NotificationContainerComponent).create(this.injector);
      notificationContainer = container.instance;
      this.appRef.attachView(container.hostView);
      const hostViewElement = container.location.nativeElement;
      const groupContainer = this.findGroupContainer(settings);
      if (!groupContainer) {
        throw new Error(`
                    View Container not found! Inject the NOTIFICATION_CONTAINER or define a specific ViewContainerRef via
                    the appendTo option. See https://www.telerik.com/kendo-angular-ui/components/notification/api/NOTIFICATION_CONTAINER/
                    for more details.
                `);
      }
      groupContainer.nativeElement.appendChild(hostViewElement);
      this.notificationContainers.push(notificationContainer);
    }
    settings.position = position;
    // eslint-disable-next-line prefer-const
    notificationRef = notificationContainer.addNotification(settings);
    return notificationRef;
  }
  get appRef() {
    if (!this.applicationRef) {
      this.applicationRef = this.injector.get(ApplicationRef);
    }
    return this.applicationRef;
  }
  findGroupContainer(settings) {
    let container;
    if (settings.appendTo) {
      container = settings.appendTo.element;
    } else if (this.container) {
      container = this.container;
    } else {
      const appRoot = this.appRef.components && this.appRef.components[0];
      container = appRoot ? appRoot.location : null;
    }
    return container;
  }
  static ɵfac = function NotificationService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NotificationService)(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(NOTIFICATION_CONTAINER, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: NotificationService,
    factory: NotificationService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.ComponentFactoryResolver
    }, {
      type: i0.Injector
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [NOTIFICATION_CONTAINER]
      }, {
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Utility array that contains all `@progress/kendo-angular-notification` related components and directives
 */
const KENDO_NOTIFICATION = [NotificationComponent, NotificationContainerComponent];

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Notification component.
 *
 * The package exports:
 * - `NotificationService`&mdash;The Notification service class.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Notification module
 * import { NotificationModule } from '@progress/kendo-angular-notification';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * _@NgModule{{
 *    declarations: [AppComponent], // declare app component
 *    imports:      [BrowserModule, NotificationModule], // import NotificationModule module
 *    bootstrap:    [AppComponent]
 * }}
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 * ```
 */
class NotificationModule {
  static ɵfac = function NotificationModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NotificationModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NotificationModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [NotificationService, IconsService],
    imports: [NotificationComponent]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotificationModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_NOTIFICATION],
      exports: [...KENDO_NOTIFICATION],
      providers: [NotificationService, IconsService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { KENDO_NOTIFICATION, NOTIFICATION_CONTAINER, NotificationComponent, NotificationContainerComponent, NotificationModule, NotificationService, NotificationSettings };