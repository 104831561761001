import { ChangeDetectionStrategy } from '@angular/core';
import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FilterOperatorComponent } from '../filter-operator.component';
import { FilterBooleanDescriptor} from './filter-boolean-descriptor';
import { FilterDescriptorEx, CompositeFilterDescriptorEx } from '../ooms-filter-descriptor';

@Component({
  selector: 'filter-boolean',
  templateUrl: './filter-boolean.component.html',
  styleUrls: ['./filter-boolean.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBooleanComponent extends FilterOperatorComponent<FilterBooleanDescriptor> {
  readonly operators = [
    {
      text: 'True',
      value: true
    },
    {
      text: 'False',
      value: false
    }
  ];

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  init(): void {
    this.formGroup = this.fb.group({
      value: [this.defaultFilterValue(0), Validators.required]
    });
  }

  public get value(): FilterDescriptorEx | CompositeFilterDescriptorEx {
    const operator = 'eq';
    const value = this.formGroup.get('value').value as string;
    return this.descriptor.createFilter(operator, value);
  }
}
