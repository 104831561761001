import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FilterBaseComponent } from '../filter-base.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FilterListDataItem } from './filter-list-data-item.model';
import { Observable, combineLatest } from 'rxjs';
import { TextBoxDirective } from '@progress/kendo-angular-inputs';
import { startWith, map, takeUntil, finalize, first } from 'rxjs/operators';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterListDescriptor } from './filter-list-descriptor';
import { FilterDescriptorEx, CompositeFilterDescriptorEx } from '../ooms-filter-descriptor';

@Component({
  selector: 'filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterListComponent extends FilterBaseComponent<FilterListDescriptor> {
  @ViewChild('textbox', { static: true, read: TextBoxDirective })
  filterInputFormGroup: UntypedFormGroup;
  filterInput: TextBoxDirective;

  @ViewChild('dataList', { static: true })
  grid: GridComponent;

  loading = false;

  filteredData: Observable<FilterListDataItem[]>;
  selected: string[] = [];
  rows: FilterListDataItem[] = [];

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  get valid(): boolean {
    return this.selected.length > 0;
  }

  get value(): FilterDescriptorEx | CompositeFilterDescriptorEx {
    return this.descriptor.createFilter(null, this.selected);
  }

  init(): void {
    this.loading = true;

    if (!!this.defaultFilter) {
      this.selected = (this.defaultFilter as CompositeFilterDescriptorEx).values;
    }

    this.filterInputFormGroup = this.fb.group({
      'filter-input': ['']
    });

    const valueChange = this.filterInputFormGroup.controls['filter-input'].valueChanges;

    this.filteredData = combineLatest(
      this.descriptor.data.pipe(
        first(),
        finalize(() => {
          this.loading = false;
        })
      ),
      valueChange.pipe(startWith(''))
    ).pipe(
      takeUntil(this._unsubscribe),
      map(([data, filter]: [FilterListDataItem[], string]) => {
        const val = filter.toLowerCase();
        let filtered;
        if (this.descriptor.mapValueTitle !== undefined) {
          filtered = data.filter(v => v.title.toLowerCase().includes(val));
        } else {
          filtered = data.filter(v => v.value.toString().toLowerCase().includes(val));
        }
        this.selected = [...this.selected.filter(s => filtered.map(f => f.value).includes(s))];

        return filtered;
      })
    );
  }
}
