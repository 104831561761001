import { Component, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FilterOperatorComponent } from '../filter-operator.component';
import { FilterNumericDescriptor } from './filter-numeric-descriptor';
import { FilterDescriptorEx, CompositeFilterDescriptorEx } from '../ooms-filter-descriptor';

const ToValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  const operator = fg.get('operator').value;
  if (operator === 'bt') {
    const value2 = fg.get('value2').value;
    return value2 ? null : { toValueInvalid: true };
  }
  return null;
};

@Component({
  selector: 'filter-numeric',
  templateUrl: './filter-numeric.component.html',
  styleUrls: ['./filter-numeric.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterNumericComponent extends FilterOperatorComponent<FilterNumericDescriptor> {
  @ViewChild('valueInput') public valueInput: ElementRef;

  readonly operators = [
    {
      text: 'Equals',
      value: 'eq'
    },
    {
      text: 'Does Not Equal',
      value: 'neq'
    },
    {
      disabled: true
    },
    {
      text: 'Greater Than',
      value: 'gt'
    },
    {
      text: 'Greater Than Or Equal To',
      value: 'gte'
    },
    {
      text: 'Less Than',
      value: 'lt'
    },
    {
      text: 'Less Than Or Equal To',
      value: 'lte'
    },
    {
      disabled: true
    },
    {
      text: 'Between',
      value: 'bt'
    }
  ];

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  init() {
    this.formGroup = this.fb.group(
      {
        operator: [this.defaultOperator || 'eq', Validators.required],
        value1: [this.defaultFilterValue(0), Validators.required],
        value2: [this.defaultFilterValue(1)]
      },
      {
        validators: [ToValidator]
      }
    );
  }

  public get value(): FilterDescriptorEx | CompositeFilterDescriptorEx {
    const operator = this.formGroup.get('operator').value as string;
    const value1 = this.formGroup.get('value1').value as number;
    const value2 = this.formGroup.get('value2').value as number;
    return this.descriptor.createFilter(operator, value1, value2);
  }

  public changeOperator(op: string) {
    window.setTimeout(() => {
      this.valueInput.nativeElement.focus();
    });
  }
}
