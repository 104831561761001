import { DateUtil } from './../utils/date.util';
import { QuickSearch } from './../models/quick-search';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import _ from 'lodash';

@Injectable()
export class QuickSearchService {
  public RESULT_SEPARATOR = ' in ';
  private readonly lineStr = '-';

  public readonly TYPE = {
    ORDERS: 'orders',
    DP_ORDER_ITEM: 'order-items',
    DP_RESOURCE: 'resource-plans',
    PP: 'jobs',
    PROJECT_PLANNER: 'projects',
    PERMIT_PLANNER: 'permits',
    QUOTES: 'quotes'
  };

  public readonly FILTERS = {
    ContactName: 'contact name',
    ContactEmail: 'contact email',
    ContactPhone: 'contact phone',
    OrderNumber: 'order number',
    JobNumber: 'job no',
    ProjectNumber: 'project no',
    QuoteNum: 'quote num',
    LeadName: 'lead name',
    LeadEmail: 'lead email',
    LeadPhone: 'lead phone',
  };

  readonly ORDER_KEY_LIST = [
    this.TYPE.ORDERS,
    this.TYPE.QUOTES,
    this.TYPE.DP_RESOURCE,
    this.TYPE.DP_ORDER_ITEM,
    this.TYPE.PP,
    this.TYPE.PROJECT_PLANNER,
    this.TYPE.PERMIT_PLANNER,
  ];

  constructor(private httpClient: HttpClient) { }

  public searchByCustomerName(site: string, name: string): Observable<QuickSearch[]> {
    const param = `$filter=contains(value, '${encodeURIComponent(this.escapeSingleQuote(name))}')`;
    return this.httpClient.get(`${environment.apiUrl}${site}/quickSearchs?${param}&$top=100`).pipe(
      map((response: any) => this.groupQuickSearch(response)
        .map((item: QuickSearch) => {
          item.displayedType = this.convertTypeVal(item.cat1);
          item.id = item.value + this.RESULT_SEPARATOR + item.displayedType;
          item.text = item.value;

          if (item.cat1 === this.TYPE.PP && item.cat3) {
            item.displayedSubType = ` (${item.cat3})`;
            item.id += item.displayedSubType;

            item.line = this.getLineOrderItem(item, response);
            if (item.line) {
              this.getTextLine(item);
            }
          } else if (item.cat1 === this.TYPE.DP_RESOURCE && item.cat3) {
            item.startDate = DateUtil.stringToLocalDateTime('' + item.cat3);
            item.displayedSubType = ` (${item.startDate.getMonth() + 1}/${item.startDate.getDate()}/${item.startDate.getFullYear()})`;
            item.id += item.displayedSubType;
          }

          if ((item.cat1 === this.TYPE.DP_RESOURCE && item.cat3) || item.cat1 === this.TYPE.DP_ORDER_ITEM || item.cat1 === this.TYPE.PERMIT_PLANNER) {
            item.line = this.getLineOrderItem(item, response);
            if (item.line) {
              this.getTextLine(item);
            }
          }

          switch (item.cat2) {
            case this.FILTERS.OrderNumber:
              item.text = `Order ${item.text}`;
              item.id = `Order ${item.id}`;
              break;
            case this.FILTERS.JobNumber:
              item.text = `Job ${item.text}`;
              item.id = `Job ${item.id}`;
              break;
            default: break;
          }

          // Update phone
          let listPhone = [item.cat6, item.cat7, item.cat8];
          item.groupPhone = listPhone.filter((item, pos) => listPhone.indexOf(item) == pos)
          .filter(item => item && item !== '')
          .join(" | ");

          return item;
        })
      )
    );
  }

  groupQuickSearch(items: QuickSearch[]) {
    let group = _(items).groupBy(item => item.value + item?.cat5)
    .sortBy(group => items.indexOf(group[0]))
    .value();

    console.log('group');
    console.log(group);

    for (const [key, groupValue] of Object.entries(group)) {
      group[key] = (groupValue as QuickSearch[]).sort((a, b) => this.ORDER_KEY_LIST.indexOf(a?.cat1) - this.ORDER_KEY_LIST.indexOf(b?.cat1));
    }

    let rs =  Object.keys(group).reduce(function (r, k) {
      return r.concat(group[k]);
    }, []);
    return rs;
  }

  private getTextLine(item: QuickSearch) {
    const txtOrderNo = item.orderId;
    item.text += ` (${this.getOrderNo(txtOrderNo)} ${this.lineStr} ${item.line})`;
  }

  private getOrderNo(text: string) {
    const txtSplit = text.split(this.lineStr);
    if (txtSplit[1]) {
      return txtSplit[1];
    }
  }

  private getLineOrderItem(item: QuickSearch, listItems: QuickSearch[]): string {
    if (item && item.orderItemId) {
      const valSplit = item.orderItemId.split(this.lineStr);
      if (valSplit[2]) {
        return valSplit[2];
      }
    }
  }

  private convertTypeVal(type: string): string {
    switch (type) {
      case this.TYPE.ORDERS:
        return 'Order List';
      case this.TYPE.DP_ORDER_ITEM:
        return 'Delivery Planner';
      case this.TYPE.DP_RESOURCE:
        return 'Delivery Planner';
      case this.TYPE.PP:
        return 'Production Planner';
      case this.TYPE.PROJECT_PLANNER:
        return 'Project Planner';
      case this.TYPE.PERMIT_PLANNER:
        return 'Permit Planner';
      case this.TYPE.QUOTES:
        return 'Quotes';
      default:
        return '';
    }
  }

  private escapeSingleQuote(value: string) {
    return value.replace(/\'/g, "''");
  }
}
